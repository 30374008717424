const data = [
    {
        id: "acrid",
        name: "Acrid",
        misc: ["Poison", "Blight"],
        primary: ["Vicious Wounds"],
        secondary: ["Neurotoxin", "Ravenous Bite"],
        utility: ["Caustic Leap", "Frenzied Leap"],
        special: ["Epidemic"],
        image: require("../images/risk/acrid/acrid.png"),
        stats: {
            health: "160 (+48 per level)",
            health_regen: {
                drizzle: "3.75/s (+0.5 per level)",
                rainstorm: "2.5/s (+0.5 per level)",
                monsoon: "1.5/s (+0.5 per level)"
            },
            damage: "15 (+3 per level)",
            speed: "7 m/s",
            armor: "20 (Drizzle - 90)"
        }
    },
    {
        id: "artificer",
        name: "Artificer",
        misc: [],
        primary: ["Flame Bolt", "Plasma Bolt"],
        secondary: ["Charged Nano-Bomb", "Charged Nano-Spear"],
        utility: ["Snapfreeze"],
        special: ["Flamethrower", "Ion Surge"],
        image:  require("../images/risk/artificer/artificer.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 90)"
        }
    },
    {
        id: "bandit",
        name: "Bandit",
        misc: [],
        primary: ["Burst", "Blast"],
        secondary: ["Serrated Dagger", "Serrated Shiv"],
        utility: ["Smoke Bomb"],
        special: ["Lights Out", "Desperado"],
        image: require("../images/risk/bandit/bandit.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "captain",
        name: "Captain",
        misc: [],
        primary: ["Vulcan Shotgun", ],
        secondary: ["Power Tazer"],
        utility: ["Orbital Probe", "OGM-72 'DIABLO' Strike"],
        special: ["Beacon: Healing", "Beacon: Shocking", "Beacon: Resupply", "Beacon: Hacking"],
        image: require("../images/risk/captain/captain.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "commando",
        name: "Commando",
        misc: [],
        primary: ["Double Tap"],
        secondary: ["Phase Round", "Phase Blast"],
        utility: ["Tactical Dive", "Tactical Slide"],
        special: ["Suppressive Fire", "Frag Grenade"],
        image: require("../images/risk/commando/commando.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "engineer",
        name: "Engineer",
        misc: [],
        primary: ["Bouncing Grenades"],
        secondary: ["Pressure Mines", "Spider Mines"],
        utility: ["Bubble Shield", "Thermal Harpoons"],
        special: ["TR12 Gauss Auto-Turret", "TR58 Carbonizer Turret"],
        image: require("../images/risk/engineer/engineer.png"),
        stats: {
            health: "130 (+39 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "14 (+2.8 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "huntress",
        name: "Huntress",
        misc: [],
        primary: ["Strafe", "Flurry"],
        secondary: ["Laser Glaive"],
        utility: ["Blink", "Phase Blink"],
        special: ["Arrow Rain", "Ballista"],
        image: require("../images/risk/huntress/huntress.png"),
        stats: {
            health: "90 (+27 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "loader",
        name: "Loader",
        misc: [],
        primary: ["Knuckleboom"],
        secondary: ["Grapple Fist", "Spiked Fist"],
        utility: ["Charged Gauntlet", "Thunder Gauntlet"],
        special: ["M551 Pylon", "Thunderslam"],
        image: require("../images/risk/loader/loader.png"),
        stats: {
            health: "160 (+48 per level)",
            health_regen: {
                drizzle: "3.75/s (+0.5 per level)",
                rainstorm: "2.5/s (+0.5 per level)",
                monsoon: "1.5/s (+0.5 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "mult",
        name: "MUL-T",
        misc: [],
        primary: ["Auto-Nailgun", "Rebar Puncher", "Scrap Launcher", "Power-Saw"],
        secondary: ["Blast Canister"],
        utility: ["Transport Mode"],
        special: ["Retool", "Power Mode"],
        image: require("../images/risk/mul-t/mul-t.png"),
        stats: {
            health: "200 (+60 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "11 (+2.2 per level)",
            speed: "7 m/s",
            armor: "12 (Drizzle - 82)"
        }
    },
    {
        id: "mercenary",
        name: "Mercenary",
        misc: [],
        primary: ["Laser Sword"],
        secondary: ["Whirlwind", "Rising Thunder"],
        utility: ["Blinding Assault", "Focused Assault"],
        special: ["Eviscerate", "Slicing Winds"],
        image: require("../images/risk/mercenary/mercenary.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "20 (Drizzle - 90)"
        }
    },
    {
        id: "rex",
        name: "REX",
        misc: [],
        primary: ["DIRECTIVE: Inject"],
        secondary: ["DIRECTIVE: Drill", "Seed Barrage"],
        utility: ["DIRECTIVE: Disperse", "Bramble Volley"],
        special: ["DIRECTIVE: Harvest", "Tangling Growth"],
        image: require("../images/risk/rex/rex.png"),
        stats: {
            health: "130 (+39 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "20 (Drizzle - 90)"
        }
    },
    {
        id: "railgunner",
        name: "Railgunner",
        misc: [],
        primary: ["XQR Smart Round System"],
        secondary: ["M99 Sniper", "HH44 Marksman"],
        utility: ["Concussion Device", "Polar Field Device"],
        special: ["Supercharge", "Cryocharge"],
        image: require("../images/risk/railgunner/railgunner.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
    {
        id: "voidfiend",
        name: "Void Fiend",
        misc: [],
        primary: ["Drown"],
        secondary: ["Flood"],
        utility: ["Trespass"],
        special: ["Suppress"],
        image: require("../images/risk/voidfiend/voidfiend.png"),
        stats: {
            health: "110 (+33 per level)",
            health_regen: {
                drizzle: "1.5/s (+0.2 per level)",
                rainstorm: "1/s (+0.2 per level)",
                monsoon: "0.6/s (+0.2 per level)"
            },
            damage: "12 (+2.4 per level)",
            speed: "7 m/s",
            armor: "0 (Drizzle - 70)"
        }
    },
]

export default data;