import React from "react";
import "./Me.css"
import BusinessCard from "./BusinessCard";
import Footer from "./Footer";
import businesscarddata from "../../data/businesscarddata";

export default function Me() {

    const cards = businesscarddata.map(element => {

        return (
            <BusinessCard
                key = {element.id}
                {...element}
            />
        )
    })

    return (
        <div className="container">
            {cards}
            <Footer/>
        </div>
    )
}