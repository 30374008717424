import React from "react";
import RandomizeButton from "./RandomizeButton";
import risksurvivordata from "../../data/risksurvivordata";
import Survivor from "./Survivor";

export default function SurvivorPanel() {
    const survivors = risksurvivordata.map(element => {

        return (
            <Survivor
                key = {element.id}
                {...element}
            />
        )
    })

    return (
    <div className="survivor-panel-container">
        <div className="survivors">
            {survivors}
        </div>
        <RandomizeButton/>
    </div>
    )
}