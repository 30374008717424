import React from "react";
import risksurvivordata from "../../data/risksurvivordata";
import {updateSelectionPanel} from "./Survivor";

export default function RandomizeButton() {

    const randomize = event => {
        //Get all elements with a border and remove the border
        let surWithBorders = document.getElementsByClassName("border");
        for (let i = 0; i < surWithBorders.length; i++) {
            if(surWithBorders[i].classList.contains("border")){
                surWithBorders[i].classList.remove("border");
            }
        }
        let randomSurvivor = risksurvivordata[Math.floor(Math.random()*risksurvivordata.length)].id;
        document.getElementById(randomSurvivor).classList.add("border");
        updateSelectionPanel(randomSurvivor);
    }

    return (
    <div className="randomize-btn-container">
        <button className="randomize-btn" onClick={randomize}>Randomize</button>
    </div>
    )
}