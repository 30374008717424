import React from "react";
import Header from "./Header";
import SurvivorPanel from "./SurvivorPanel";
import SurvivorSelection from "./SurvivorSelection";
import "./risk.css";
import Footer from "./Footer";

export default function RiskOfRaindomizer() {
    return (
        <div className="risk-html">
            <Header/>
            <div className="risk-container">
                <SurvivorPanel/>
                <SurvivorSelection/>
            </div>
            <Footer/>
        </div>
    )
}