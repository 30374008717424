export default [
    {
        id: 1,
        name: "Thomas Bridges",
        jobTitle: "Application Developer",
        website: "tombridges.dev",
        about: "I am an application developer currently learning React for web development. " +
            "I built this \"business card\" using React. How did I do?",
        interests: "Hiking\nCoding\nLearning\nBasketball",
        backInfoTitle: "Back of Card",
        backInfoText: "More info to come later!" +
            "\n\n\n" +
            ":)"
    },
    // {
    //     id: 2,
    //     name: "Thomas Bridges",
    //     jobTitle: "Application Developer",
    //     website: "tombridges.dev",
    //     about: "I am an application developer currently learning React for web development. " +
    //         "I built this \"business card\" using React. How did I do?",
    //     interests: "Hiking\nCoding\nLearning\nBasketball",
    //     backInfoTitle: "Back of Card",
    //     backInfoText: "More info to come later!" +
    //         "\n\n\n" +
    //         ":)"
    // },
    // {
    //     id: 3,
    //     name: "Thomas Bridges",
    //     jobTitle: "Application Developer",
    //     website: "tombridges.dev",
    //     about: "I am an application developer currently learning React for web development. " +
    //         "I built this \"business card\" using React. How did I do?",
    //     interests: "Hiking\nCoding\nLearning\nBasketball",
    //     backInfoTitle: "Back of Card",
    //     backInfoText: "More info to come later!" +
    //         "\n\n\n" +
    //         ":)"
    // },

]