import React from "react";
import artificer from "../../images/risk/artificer/artificer-large.png";
import risksurvivordata from "../../data/risksurvivordata";
export default function SurvivorSelection() {
    return (
        <div className="survivor-selection">
            <div className="survivor-pfp-panel">
                <img className="risk-pfp" src={artificer} alt="Artificer"></img>
            </div>
            <div className="stats span-3" id = "stats">
                <span className="center name" id= "name">{risksurvivordata[0].name}</span>
                    <ul className="stats-list">
                        <li id = "health">Health: {risksurvivordata[0].stats.health}</li>
                        <li id = "health-regen-1">Health Regen - Drizzle {risksurvivordata[0].stats.health_regen.drizzle}</li>
                        <li id = "health-regen-2">Health Regen - Rainstorm {risksurvivordata[0].stats.health_regen.rainstorm}</li>
                        <li id = "health-regen-3">Health Regen - Monsoon {risksurvivordata[0].stats.health_regen.monsoon}</li>
                        <li id = "damage">Damage: {risksurvivordata[0].stats.damage}</li>
                        <li id = "speed">Speed: {risksurvivordata[0].stats.speed}</li>
                        <li id = "armor">Armor: {risksurvivordata[0].stats.armor}</li>
                    </ul>
            </div>
            <div className="loadout span-4">
                <div className="primary span-2 loadout-spacing">
                    <span>Primary: </span>
                    <span id = "primary-1">{risksurvivordata[0].primary[0]}</span>
                    <span id = "primary-2">{risksurvivordata[0].primary[1]}</span>
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                </div>
                <div className="secondary span-2 loadout-spacing">
                    <span>Secondary: </span>
                    <span id = "secondary-1">{risksurvivordata[0].secondary[0]}</span>
                    <span id = "secondary-2">{risksurvivordata[0].secondary[1]}</span>
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                </div>
                <div className="utility span-2 loadout-spacing">
                    <span>Utility: </span>
                    <span id = "utility-1">{risksurvivordata[0].utility[0]}</span>
                    <span id = "utility-2">{risksurvivordata[0].utility[1]}</span>
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                </div>
                <div className="misc span-2 loadout-spacing">
                    <span>Misc: </span>
                    <span id = "misc-1">{risksurvivordata[0].misc[0]}</span>
                    <span id = "misc-2">{risksurvivordata[0].misc[1]}</span>
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                </div>
                <div className="special span-4 loadout-spacing">
                    <span>Special: </span>
                    <span id = "special-1">{risksurvivordata[0].special[0]}</span>
                    <span id = "special-2">{risksurvivordata[0].special[1]}</span>
                    <span id = "special-3">{risksurvivordata[0].special[2]}</span>
                    <span id = "special-4">{risksurvivordata[0].special[3]}</span>
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                    {/*<img src={risksurvivordata[0].image} alt ="passive-1" />*/}
                </div>
            </div>
        </div>
    )
}