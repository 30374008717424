import App from "./App";
import OtherPage from "./pages/OtherPage";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Whoops from "./pages/Whoops";
import RiskOfRaindomizer from "./pages/riskofraindomizer/RiskOfRaindomizer";
import Me from "./pages/me/Me";

export default function AllRoutes(){
    return (
        <Routes>
            <Route path="/" element={ <Navigate to="/me" /> } />
            <Route path= "/me" element={<Me/>}/>
            <Route path= "/other" element={<OtherPage/>}/>
            <Route path= "/risk" element={<RiskOfRaindomizer/>}/>
            <Route path= "*" element={<Whoops/>}/>
        </Routes>
    )
}