import React, {useEffect} from "react";
import risksurvivordata from "../../data/risksurvivordata";

function resetLoadout() {
    document.getElementById("primary-1").innerTxt = "";
    document.getElementById("primary-2").innerTxt = "";
    document.getElementById("secondary-1").innerTxt = "";
    document.getElementById("secondary-2").innerTxt = "";
    document.getElementById("utility-1").innerTxt = "";
    document.getElementById("utility-2").innerTxt = "";
    document.getElementById("misc-1").innerTxt = "";
    document.getElementById("misc-2").innerTxt = "";
    document.getElementById("special-1").innerTxt = "";
    document.getElementById("special-2").innerTxt = "";
    document.getElementById("special-3").innerTxt = "";
    document.getElementById("special-4").innerTxt = "";

}
export function updateSelectionPanel(selection) {
    let survivorJsonData = search_array(risksurvivordata, selection);
    //update stats section
    document.getElementById("name").innerText = survivorJsonData.name;
    document.getElementById("health").innerText = "Health: " + survivorJsonData.stats.health;
    document.getElementById("health-regen-1").innerText = "Health Regen - Drizzle: " + survivorJsonData.stats.health_regen.drizzle;
    document.getElementById("health-regen-2").innerText = "Health Regen - Rainstorm: " + survivorJsonData.stats.health_regen.rainstorm;
    document.getElementById("health-regen-3").innerText = "Health Regen - Monsoon: " + survivorJsonData.stats.health_regen.monsoon;
    document.getElementById("damage").innerText = "Damage: " + survivorJsonData.stats.damage;
    document.getElementById("speed").innerText = "Speed: " + survivorJsonData.stats.speed;
    document.getElementById("armor").innerText = "Armor: " + survivorJsonData.stats.armor;



    resetLoadout();
    
    if(survivorJsonData.primary[0])
        document.getElementById("primary-1").innerText =survivorJsonData.primary[0];
    if(survivorJsonData.primary[1])
        document.getElementById("primary-2").innerText =survivorJsonData.primary[1];
    if(survivorJsonData.secondary[0])
        document.getElementById("secondary-1").innerText =survivorJsonData.secondary[0];
    if(survivorJsonData.secondary[1])
        document.getElementById("secondary-2").innerText =survivorJsonData.secondary[1];
    if(survivorJsonData.utility[0])
        document.getElementById("utility-1").innerText =survivorJsonData.utility[0];
    if(survivorJsonData.utility[1])
        document.getElementById("utility-2").innerText =survivorJsonData.utility[1];
    if(survivorJsonData.misc[0])
        document.getElementById("misc-1").innerText =survivorJsonData.misc[0];
    if(survivorJsonData.misc[1])
        document.getElementById("misc-2").innerText =survivorJsonData.misc[1];
    if(survivorJsonData.special[0])
        document.getElementById("special-1").innerText =survivorJsonData.special[0];
    if(survivorJsonData.special[1])
        document.getElementById("special-2").innerText =survivorJsonData.special[1];
    if(survivorJsonData.special[2])
        document.getElementById("special-3").innerText =survivorJsonData.special[2];
    if(survivorJsonData.special[3])
        document.getElementById("special-4").innerText =survivorJsonData.special[3];


}
function search_array(array,valuetofind) {
    for (let i = 0; i < array.length; i++) {
        if (array[i]['id'] === valuetofind) {
            return array[i];
        }
    }
    return -1;
}



export default function Survivor(props) {

    const handleClick = event => {
        let surWithBorders = document.getElementsByClassName("border");
        for (let i = 0; i < surWithBorders.length; i++) {
            if(surWithBorders[i].classList.contains("border")){
                surWithBorders[i].classList.remove("border");
            }
        }
        let selectedSurvivor = event.currentTarget.id;
        if(document.getElementById(selectedSurvivor).classList.contains("border")){
            document.getElementById(selectedSurvivor).classList.remove("border");
        }
        else
            document.getElementById(selectedSurvivor).classList.add("border");

        updateSelectionPanel(selectedSurvivor);
    }

    useEffect(() => {
        document.getElementsByClassName("survivors")[0].children[0].classList.add("border");
    }, []);

    return (
        <img src = {props.image} id={props.id} alt = {props.name} className="survivor-icon" title={props.name} onClick={handleClick}></img>
    )
}