import React from "react"
export default function About(props) {
    return (
        <div className="about-container">
            <h1 className="about-title">About Me</h1>
            <span className="about-text">
                {props.about}
            </span>
        </div>
    )
}