import React from "react"
export default function Interests(props) {
    return (
        <div className="interests-container">
            <h1 className="interests-title">Interests</h1>
            <span className="interests-text">
                {props.interests}
            </span>
        </div>
    )
}