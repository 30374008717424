import React from "react"
import ProfilePic from "./ProfilePic";
import "./BusinessCard.css"
import ContactInfo from "./ContactInfo";
import Email from "./Email";
import About from "./About";
import Interests from "./Interests";
import CardBack from "./CardBack";
export default function BusinessCard(props) {
    
        return (
            <div className="b_card-container">
                <div className="card">
                    <div className="card-front">
                        <ProfilePic/>
                        <ContactInfo
                            name = {props.name}
                            title = {props.title}
                            website = {props.website}
                        />
                        <Email/>
                        <About
                            about = {props.about}
                        />
                        <Interests
                            interests = {props.interests}
                        />
                    </div>
                    <CardBack
                        backInfoTitle = {props.backInfoTitle}
                        backInfoText = {props.backInfoText}
                    />
                </div>
            </div>
        )
}