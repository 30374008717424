import React from "react";
import email_icon from "../../images/email-icon.png";
export default function Email() {
    return (

    <div className="email-box">
        <div className="email-container">
            <img className="email-icon" src={email_icon} alt="email-icon"/>
            <span className="email-text">Email</span>
        </div>
    </div>


    )
}